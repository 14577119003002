// This file was generated using GraphQL Codegen. Command: `yarn gql`
// For more info and docs, visit https://graphql-code-generator.com/
// @ts-ignore
// Added ts-ignore because of this bug https://github.com/dotansimha/graphql-code-generator/issues/4900
import * as Types from '../types.generated';

import { gql } from '@apollo/client';
export type RuntimeBasicInfoFragment = { __typename: 'Runtime', id: string, version: string };

export const RuntimeBasicInfoFragmentDoc = gql`
    fragment RuntimeBasicInfo on Runtime {
  __typename
  id
  version
}
    `;