import { HelpCircleIcon } from '@expo/styleguide-icons/outline/HelpCircleIcon';

import { BuildProfileExplainer } from '~/scenes/Dashboard/Builds/BuildDetailsScene/explainers';
import { HeaderTheme, TableHeader } from '~/ui/components/Table/TableHeader';
import { TableHeaderCell } from '~/ui/components/Table/TableHeaderCell';
import { TableHeaderCellText } from '~/ui/components/Table/TableHeaderCellText';
import { TooltipContent } from '~/ui/components/Tooltip/TooltipContent';
import { TooltipRoot } from '~/ui/components/Tooltip/TooltipRoot';
import { TooltipTrigger } from '~/ui/components/Tooltip/TooltipTrigger';

type BuildsHeaderProps = {
  gitColumnName?: string;
  theme?: HeaderTheme;
  hasDeployment?: boolean;
};

export function BuildsHeader({ gitColumnName, hasDeployment, theme }: BuildsHeaderProps) {
  return (
    <TableHeader theme={theme}>
      <TableHeaderCell className="w-full">
        <TableHeaderCellText>Build</TableHeaderCellText>
      </TableHeaderCell>
      <TableHeaderCell hideOnMobile>
        <TableHeaderCellText>{gitColumnName ?? 'Git ref'}</TableHeaderCellText>
      </TableHeaderCell>
      <TableHeaderCell hideOnMobile>
        <TableHeaderCellText>Profile</TableHeaderCellText>
        <TooltipRoot>
          <TooltipTrigger>
            <HelpCircleIcon className="icon-sm text-icon-tertiary" />
          </TooltipTrigger>
          <TooltipContent className="w-72 px-3 py-2">
            <BuildProfileExplainer />
          </TooltipContent>
        </TooltipRoot>
      </TableHeaderCell>
      {hasDeployment ? (
        <TableHeaderCell hideOnMobile>
          <TableHeaderCellText>Deployment</TableHeaderCellText>
        </TableHeaderCell>
      ) : (
        <>
          <TableHeaderCell hideOnMobile>
            <TableHeaderCellText>Runtime version</TableHeaderCellText>
          </TableHeaderCell>
          <TableHeaderCell hideOnMobile>
            <TableHeaderCellText>Channel</TableHeaderCellText>
          </TableHeaderCell>
        </>
      )}
    </TableHeader>
  );
}
