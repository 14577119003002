import { Link } from '@expo/styleguide';
import { AlignHorizontalCentre02Icon } from '@expo/styleguide-icons/outline/AlignHorizontalCentre02Icon';
import { Cube02Icon } from '@expo/styleguide-icons/outline/Cube02Icon';
import { XCircleIcon } from '@expo/styleguide-icons/outline/XCircleIcon';
import { format } from 'date-fns/format';
import { useEffect, useState } from 'react';

import { dateFnsFormats } from '~/common/constants';
import { formatDuration } from '~/common/format-duration';
import { useRuntimesConceptFeatureGate } from '~/common/gating/gates/RuntimesConceptFeatureGate';
import { getVariableTimeString } from '~/common/getVariableTimeString';
import { getProjectURL } from '~/common/helpers';
import { TableBuildFragment } from '~/graphql/types.generated';
import {
  getBuildStatus,
  getBuildTitle,
  getPlatformIcon,
  isBuildComplete,
  isBuildExpired,
} from '~/scenes/Dashboard/Builds/helpers';
import { ElapsedTime } from '~/scenes/Dashboard/components/DashboardLogs/ElapsedTime';
import { ContentLoading } from '~/ui/components/ContentLoading';
import { StatusIndicator } from '~/ui/components/StatusIndicator';
import { TableCell } from '~/ui/components/Table/TableCell';
import { TableCellText } from '~/ui/components/Table/TableCellText';
import { TableEmptyCellText } from '~/ui/components/Table/TableEmptyCellText';
import { TableInlineCell } from '~/ui/components/Table/TableInlineCell';
import { TableRow } from '~/ui/components/Table/TableRow';
import { TableSeparator } from '~/ui/components/Table/TableSeparator';
import { TooltipContent } from '~/ui/components/Tooltip/TooltipContent';
import { TooltipRoot } from '~/ui/components/Tooltip/TooltipRoot';
import { TooltipText } from '~/ui/components/Tooltip/TooltipText';
import { TooltipTrigger } from '~/ui/components/Tooltip/TooltipTrigger';
import { CALLOUT } from '~/ui/components/text';

import { DeploymentCell } from './DeploymentCell';
import { GitRefCell, MobileGitRefCell } from './GitRefCell';

type BuildRowProps = {
  accountName: string;
  build: TableBuildFragment;
  disableLinks?: boolean;
  hideTimestamp?: boolean;
  currentDateAsString?: string;
  isActivityView?: boolean;
  onBuildClicked?: () => void;
};

export function BuildRow({
  accountName,
  build,
  disableLinks,
  hideTimestamp,
  currentDateAsString,
  isActivityView,
  onBuildClicked,
}: BuildRowProps) {
  const [renderDate, setRenderDate] = useState(false);
  const runtimesConceptFeatureGate = useRuntimesConceptFeatureGate();

  useEffect(() => {
    setRenderDate(true);
  }, []);

  const buildTitle = getBuildTitle(
    { ...build, platform: build.buildPlatform },
    { showVersionNumber: true, isActivityView }
  ).buildTitleElement;
  const PlatformIcon = getPlatformIcon(build.buildPlatform);

  const duration =
    build.metrics?.buildDuration &&
    build.metrics.buildDuration !== 0 &&
    formatDuration(build.metrics?.buildDuration);

  const isExpired = isBuildExpired(build, currentDateAsString);

  const primaryCell = (
    <TableCell
      isActivityView={isActivityView}
      mobilePrimaryCell
      theme={disableLinks ? 'default' : 'interactive'}
      className="flex w-full min-w-0 max-w-full flex-col gap-1">
      <div className="flex min-w-0 items-center gap-3">
        <TooltipRoot>
          <TooltipTrigger className="flex">
            <StatusIndicator status={build.buildStatus} isExpired={isExpired} />
          </TooltipTrigger>
          <TooltipContent>
            <CALLOUT>{getBuildStatus(build.buildStatus)}</CALLOUT>
          </TooltipContent>
        </TooltipRoot>
        <div className="flex min-h-[46px] min-w-0 flex-col justify-center max-md-gutters:min-h-[unset]">
          <div className="flex items-center gap-1.5">
            <PlatformIcon className="icon-sm inline shrink-0" />
            <CALLOUT weight="medium" className="truncate">
              {buildTitle}
            </CALLOUT>
          </div>
          {!hideTimestamp && (
            <ContentLoading
              loading={!renderDate}
              height={24}
              width={150}
              wrapperClassName="flex truncate">
              <TooltipRoot>
                <TooltipTrigger className="flex gap-3 truncate !outline-offset-[-3px]">
                  <CALLOUT theme="secondary">{getVariableTimeString(build.createdAt)}</CALLOUT>
                  {duration && (
                    <ElapsedTime
                      duration={build.metrics?.buildDuration ?? 0}
                      startTime={build.createdAt}
                      hasEnded={isBuildComplete(build.buildStatus)}
                    />
                  )}
                  {isExpired && (
                    <CALLOUT className="flex items-center gap-1.5" theme="secondary">
                      <XCircleIcon className="icon-xs text-icon-quaternary" />
                      Expired
                    </CALLOUT>
                  )}
                </TooltipTrigger>
                <TooltipContent side="bottom" align="start">
                  <TooltipText className="text-left">
                    Started {format(new Date(build.createdAt), dateFnsFormats.timestamp)}
                    {duration && (
                      <>
                        <br />
                        Finished in {duration}
                      </>
                    )}
                    {isExpired && (
                      <>
                        <br />
                        Build artifact has expired.
                      </>
                    )}
                  </TooltipText>
                </TooltipContent>
              </TooltipRoot>
            </ContentLoading>
          )}
        </div>
      </div>
      <div className="hidden flex-wrap gap-2 max-md-gutters:mt-1.5 max-md-gutters:flex">
        {(build.buildGitCommitHash || build.gitRef) && (
          <TableInlineCell>
            <CALLOUT theme="secondary" className="whitespace-nowrap">
              Git ref:
            </CALLOUT>
            <MobileGitRefCell
              gitCommitHash={build.buildGitCommitHash}
              isGitWorkingTreeDirty={build.buildIsGitWorkingTreeDirty}
              gitRef={build.gitRef}
            />
          </TableInlineCell>
        )}
        <TableInlineCell>
          <CALLOUT theme="secondary">Profile:</CALLOUT>
          <CALLOUT>{build.buildProfile}</CALLOUT>
        </TableInlineCell>
        {build.buildChannel && (
          <TableInlineCell>
            <Cube02Icon className="icon-sm shrink-0" />
            <CALLOUT>{build.buildChannel.name}</CALLOUT>
          </TableInlineCell>
        )}
      </div>
    </TableCell>
  );

  const channelCell = (
    <TableCell
      hideOnMobile
      className="flex min-h-[44px] w-full items-center gap-2 self-stretch truncate max-md-gutters:hidden"
      theme={disableLinks ? 'default' : 'interactive'}>
      <Cube02Icon className="shrink-0" />
      <TableCellText className="truncate">{build.buildChannel?.name}</TableCellText>
    </TableCell>
  );

  const runtimeCell = build.buildRuntime ? (
    <TableCell
      hideOnMobile
      className="flex min-h-[44px] w-full items-center gap-2 self-stretch truncate max-md-gutters:hidden"
      theme={disableLinks || !runtimesConceptFeatureGate.isEnabled() ? 'default' : 'interactive'}>
      <AlignHorizontalCentre02Icon className="shrink-0" />
      <TableCellText className="truncate">{build.buildRuntime.version}</TableCellText>
    </TableCell>
  ) : null;

  const deploymentUrl = build.deployment
    ? getProjectURL(
        build.app.ownerAccount.name,
        build.app.slug,
        `deployments/${encodeURIComponent(build.deployment.channel.name)}/${encodeURIComponent(
          build.deployment.runtime.version
        )}`
      )
    : undefined;

  return (
    <>
      <TableSeparator />
      <TableRow>
        {disableLinks ? (
          primaryCell
        ) : (
          <Link
            onClick={
              onBuildClicked
                ? () => {
                    onBuildClicked();
                  }
                : undefined
            }
            href={getProjectURL(accountName, build.app.slug, `builds/${build.id}`)}
            className="group flex size-full max-w-full">
            {primaryCell}
          </Link>
        )}
        <GitRefCell
          hideOnMobile
          app={build.app}
          gitCommitHash={build.buildGitCommitHash}
          gitCommitMessage={build.buildGitCommitMessage}
          isGitWorkingTreeDirty={build.buildIsGitWorkingTreeDirty}
          gitRef={build.gitRef}
          disableLinks={disableLinks}
        />
        <TableCell hideOnMobile>
          <TableCellText className="truncate">{build.buildProfile}</TableCellText>
        </TableCell>
        {isActivityView && build.deployment && deploymentUrl && (
          <DeploymentCell
            hideOnMobile
            runtimeVersion={build.deployment.runtime.version}
            channel={build.deployment.channel.name}
            url={deploymentUrl}
          />
        )}
        {/* when in the table view, the runtime version and channel are rendered in the header as the lowest common denominator */}
        {(!isActivityView || !build.deployment) && (
          <>
            {build.buildRuntime ? (
              disableLinks || !runtimesConceptFeatureGate.isEnabled() ? (
                runtimeCell
              ) : (
                <Link
                  href={getProjectURL(
                    accountName,
                    build.app.slug,
                    `runtimes/${encodeURIComponent(build.buildRuntime.id)}`
                  )}
                  className="flex h-full items-center truncate max-md-gutters:hidden">
                  {runtimeCell}
                </Link>
              )
            ) : (
              <TableCell hideOnMobile>
                <TableEmptyCellText />
              </TableCell>
            )}
            {build.buildChannel ? (
              disableLinks ? (
                channelCell
              ) : (
                <Link
                  href={getProjectURL(
                    accountName,
                    build.app.slug,
                    `channels/${encodeURIComponent(build.buildChannel.name)}`
                  )}
                  className="flex h-full items-center truncate max-md-gutters:hidden">
                  {channelCell}
                </Link>
              )
            ) : (
              <TableCell hideOnMobile>
                <TableEmptyCellText />
              </TableCell>
            )}
          </>
        )}
      </TableRow>
    </>
  );
}
