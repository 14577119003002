import { A, CODE, CALLOUT, HEADLINE, BOLD } from '~/ui/components/text';

export function ChannelExplainer() {
  return (
    <div className="p-2 pt-1.5">
      <HEADLINE className="mb-1">Channel</HEADLINE>
      <CALLOUT>
        Channels are build-specific names that allow you to associate different update branches with
        a build. For instance, if you make Android and iOS builds with the channel "main", then when
        you publish an update to a branch associated with the "main" channel, both builds will
        receive the new update. Defined in <BOLD>eas.json</BOLD>.
      </CALLOUT>
    </div>
  );
}

export function BuildProfileExplainer() {
  return (
    <div className="p-2 pt-1.5">
      <HEADLINE className="mb-1">Profile</HEADLINE>
      <CALLOUT>
        A profile specifies the configuration options for different types of builds you may run for
        your project with EAS Build. For example, you may have a profile for internal distribution
        named "preview" and one for app store builds named "production". Build profiles are defined
        in your project's <BOLD>eas.json</BOLD> and each build that you run with{' '}
        <CODE>eas-cli</CODE> is associated with a profile.{' '}
        <A href="https://docs.expo.dev/build/eas-json/#build-profiles" isStyled openInNewTab>
          Learn more
        </A>
        .
      </CALLOUT>
    </div>
  );
}

export function AvailabilityExplainer() {
  return (
    <div className="p-2 pt-1.5" id="build-artifact-availability">
      <HEADLINE className="mb-1">Download and logs availability</HEADLINE>
      <CALLOUT className="mb-5">
        Build job artifacts such as binaries (.ipa, .aab, .apk, etc.) and log files are
        automatically cleaned up from our servers after 30 days (unless they're built for internal
        distribution, in which case they're removed after 14 or 90 days, depending on whether it was
        a free or paid tier build). As a result, you don't have to manually clean up binaries if you
        would no longer like them to be stored. If you need another binary to deploy to your users,
        you can create a new one with{' '}
        <A
          href="https://docs.expo.dev/build/setup/#1-install-the-latest-eas-cli"
          isStyled
          openInNewTab>
          <CODE>eas-cli</CODE>
        </A>
        .
      </CALLOUT>
      <CALLOUT weight="medium">
        The app binaries you download and deploy will always continue working! Only the copy of the
        file that exists in our storage will be removed.
      </CALLOUT>
    </div>
  );
}
