import { mergeClasses } from '@expo/styleguide';
import { ClockIcon } from '@expo/styleguide-icons/outline/ClockIcon';
import { useState, useRef, useEffect } from 'react';

import { formatDuration } from '~/common/format-duration';
import { CALLOUT, TextElementUnion } from '~/ui/components/text';

type Props = {
  startTime: string;
  duration?: number;
  hasEnded?: boolean;
  className?: string;
  tag?: TextElementUnion;
};

export function ElapsedTime({ startTime, duration, hasEnded, className, tag }: Props) {
  const [elapsed, setElapsed] = useState(hasEnded && duration ? duration : 0);
  const [shouldRenderDate, setShouldRenderDate] = useState(false);
  const interval = useRef<number | null>(null);

  function setElapsedTime() {
    const elapsed = Date.now() - new Date(startTime).getTime();
    setElapsed(elapsed);
  }

  useEffect(function didMount() {
    setShouldRenderDate(true);

    if (!hasEnded) {
      setElapsedTime();
      interval.current = window.setInterval(setElapsedTime, 1000);
    }

    return function willUnmount() {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, []);

  useEffect(
    function hasEndedDidUpdate() {
      if (hasEnded) {
        if (interval.current) {
          clearInterval(interval.current);
        }

        if (duration) {
          setElapsed(duration);
        }
      }
    },
    [hasEnded]
  );

  if (!hasEnded && elapsed <= 0) {
    return null;
  }

  return shouldRenderDate ? (
    <CALLOUT
      tag={tag}
      className={mergeClasses(
        'variant-numeric-tabular flex items-center gap-1.5 whitespace-nowrap',
        className
      )}
      theme="secondary">
      <ClockIcon className="icon-xs text-icon-quaternary" />
      {formatDuration(Math.max(elapsed, 1000))}
    </CALLOUT>
  ) : null;
}
