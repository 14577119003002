// This file was generated using GraphQL Codegen. Command: `yarn gql`
// For more info and docs, visit https://graphql-code-generator.com/
// @ts-ignore
// Added ts-ignore because of this bug https://github.com/dotansimha/graphql-code-generator/issues/4900
import * as Types from '../types.generated';

import { gql } from '@apollo/client';
export type UpdateChannelBasicInfoFragment = { __typename: 'UpdateChannel', id: string, name: string, branchMapping: string, createdAt: any, updatedAt: any, isPaused: boolean };

export const UpdateChannelBasicInfoFragmentDoc = gql`
    fragment UpdateChannelBasicInfo on UpdateChannel {
  __typename
  id
  name
  branchMapping
  createdAt
  updatedAt
  isPaused
}
    `;